@import "../../../assets/style/mixins.less";

.container {
  background-color: #FFF;
  //padding-bottom: 15px;

  :global(.ant-table-thead) {
    box-shadow: none !important;
  }

  .date {
    background-color: #F5F7FB;
    // height: 30px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    p {
      font-size: 13px;
      color : @navy1
    }
  }
  .title-box{
    border-bottom: 1px solid #EBEBEB;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    p {
      font-size: 15px;
      color : @navy1;
      font-weight: 700 !important;
    }
  }
  .box-info {
    padding-left: 15px;
  };
  .left-thong-tin {
    border-right: 1px solid #EBEBEB;
  };
  .text-bold {
    font-size: 13px;
    color : @navy1;
    font-weight: bold !important;
  }
  p {
    font-size: 13px;
    color : @navy1;
    font-weight: 600;
    // margin-top: 5px;
  }
  .money {
    font-size: 14px;
    font-weight: 700 !important;
    color : #2CB3A5;
    margin-left: 10px;
  }
}

.total-price-ctn {
  margin-top: 20px;
  padding: 0px 15px;
  text-align: end;
  color: @navy1;
  font-weight: 600 !important;
  font-size: 14px !important;

  .total-price {
    color: @green1;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
}

.detailBtn{
  padding-inline: 23px;
}


.table {
  :global {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
    }
    
    td, th {
      border: 1px solid #dddddd;
      // text-align: center;
      padding: 8px;
      color: #2c3782;
    }
    th:first-child {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
  
}

.ds_ket_qua_cdha {
  * {
    font-family: "Quicksand", sans-serif !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #2c3782 !important;
    span {
      font-family: "Quicksand", sans-serif  !important;
      font-weight: 600 !important;
      font-size: 13px !important;
      color: #2c3782 !important;
    }
  }
  span {
    font-family: "Quicksand", sans-serif  !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #2c3782 !important;
  }
}
