@import "./vars.less";

.txt-ellipsis(@row: 1) {
  display: -webkit-box;
  -webkit-line-clamp: @row;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.style-scroll-bar(@wid: 10px,@height: 10px) {
  /* width */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: @wid !important;
    height: @height !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(186, 192, 198);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent !important;
  }
}

.style-scroll-bar-hide(@wid: 10px,@height: 10px, @thumb: #f6f8fe) {
  /* width */
  // scroll-behavior: smooth;
  // &::-webkit-scrollbar {
  //   width: @wid;
  //   height: @height !important;
  //   cursor: pointer !important;
  // }
  // &::-webkit-scrollbar-corner {
  //   background-color: transparent !important;
  // }
  /* Handle */
  // &::-webkit-scrollbar-track {
  //   color: transparent;
  //   background-color: transparent;
  //   cursor: pointer !important;
  //   &:hover {
  //     cursor: pointer !important;
  //     background-color: #ddd;
  //   }
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: @thumb;
  //   cursor: pointer !important;
  //   &:hover {
  //     cursor: pointer !important;
  //     background-color: #a7aab1;
  //   }
  //   transition: all 1s linear;
  // }

  /* New */
  &::-webkit-scrollbar {
    width: @wid !important;
    height: @height !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
}
