@import "../../assets/style/mixins.less";

.container {
  :global(.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first)::after {
    box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 8%);
  }

  :global(.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active)) {
    a {
      color: @navy1;
    }
  }

  :global(.ant-pagination-item-active) {
    background: #6576ff;
    border-radius: 50%;
    color: #fff;
    border-color: #6576ff;
    a {
      color: #fff;
    }
  }

  :global(.ant-table-cell-row-hover) {
    // color: #fff !important;
  }
  :global(.ant-pagination) {
    // color: #fff !important;
      li:has(a) {
        display: none;
    }
  }
  :global(.ant-table-body) {
    .style-scroll-bar-hide(10px,10px);
  } 

  .row-hover {
    // background-color: #863333;
    :global(.ant-table-cell) {
      background-color: #ececec;
    }
  }

  :global(.ant-table-cell) {
    background-color: #fff;
    color: @navy1;
  }

  :global(.ant-table-thead) {
    box-shadow: 0 0 4px #00000010;
    border-radius: 5px;

    // * {
    //   font-weight: 600 !important;
    // }

    :global(.ant-table-cell) {
      border: none !important;

      &::before {
        width: 0px !important;
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  tbody {
    tr {
      box-shadow: 0 0 4px #00000010;
      border-radius: 5px;
    }

    tr:first-child {
      td:first-child {
        border-radius: 5px 0 0 0;
      }
      td:last-child {
        border-radius: 0 5px 0 0;
      }
    }

    tr:last-child {
      td:first-child {
        border-radius: 0 0 0 5px;
      }
      td:last-child {
        border-radius: 0 0 5px 0;
      }
    }
  }

  &.show-gap tbody {
    &::before {
      content: "@";
      display: block;
      line-height: 15px;
      text-indent: -99999px;
    }
  }

  &.show-gap--sm tbody {
    &::before {
      content: "@";
      display: block;
      line-height: 8px;
      text-indent: -99999px;
    }
  }

  &.only-one tr {
    td:first-child {
      border-radius: 5px 0 0 5px !important;
    }
    td:last-child {
      border-radius: 0 5px 5px 0 !important;
    }
  }

  :global(.ant-table-cell-row-hover) {
    :global(.green-txt),
    :global(.red-txt),
    :global(.blue-txt),
    :global(.navy-txt),
    :global(.orange-txt) {
      // color: #fff;
    }
  }

  thead tr th {
    &:global(.ant-table-cell) {
      font-weight: 700 !important;
    }
  }
}

.clickable {
  cursor: pointer;
}

.pagination {
  margin-top: 15px;
  padding-right: 8px;
  .text {
    color: @navy1;
    margin-right: 10px;
  }

  button {
    border: none !important;
    border-radius: 50px !important;
    box-shadow: none !important;
    background-color: #f6f9fc !important;

    * {
      color: @navy1 !important;
    }

    &:hover {
      background-color: #f0f0f0 !important;
    }
  }

  button:disabled {
    background-color: #f6f9fc !important;

    * {
      color: #cecece !important;
    }
  }
}
