@import "../../assets/style/mixins.less";

.container {
  background-color: #FFF;
  border-radius: 10px;
  padding-top: 10px;
  height: calc(100vh - @header-height * 2 - 80px);
  overflow: auto;
  .content{
    height: calc(100vh - @header-height * 2 - 130px);
    overflow: auto;
    display: none;
    .style-scroll-bar;
  }
  .content-active {
    .content;
    display: block;
  }
  .button {
    background-color: #F4F6FA;
    color : #2C3782;
    min-width: 130px;
    max-width:"fit-content";
    height: 28px;
    border: none;
    margin-right: 10px;
  }
  .button-active {
    .button ;
    background-color: #6576FF;
    color : #FFF
  }
}
.container-tiepdon {
  .container;
  padding: 10px;
  height: calc(100vh - @header-height * 2 ) !important;
}
.content-tiepdon{
  height: calc(100vh - @header-height * 2 - 60px) !important;
}

