.container {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 20px 0px #0000001A;
    width: fit-content;
    
    span {
        color: #2C3782;
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    .wait-payment {
        color: #6576FF;
        font-size: 14px !important;
        font-weight: 700 !important;
    }
    .item-container {
        // padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #E7E7E7;
    }
    .debt {
        border-radius: 6px;
        border: 1px solid #F34946;
        // padding: 10px;
        padding-left: 10px;
        padding-right: 10px;
        //margin-left: 10px;
        span {
            color: #F34946;
            font-size: 14px !important;
            font-weight: 700 !important;
        }
    }
    @media screen and (max-width: 1550px) {
        .line {
          background-color: white;
        }
        .item-container {
            border: none !important;
        }
      }
}

.main-thanh-toan{
    h4 {
        margin: 15px 0 0 17px;
    }

    :global {
        .money_container {
            width: 100%;
            padding: 0 15px;
        }
    }
    
}

.classNameContainer{
    height: 100% !important;

    :global {
        .ant-layout {
            height: 100%;
        }

        .ant-layout-sider{
            height: 100% !important;
        }
    }
  }