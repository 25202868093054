@font-face {
  font-family: "Quicksand";
  src: url("../font/Quicksand-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Quicksand";
  src: url("../font/Quicksand-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Quicksand";
  src: url("../font/Quicksand-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Quicksand";
  src: url("../font/Quicksand-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Quicksand";
  src: url("../font/Quicksand-Bold.ttf");
  font-weight: 700;
}
