@import "../../../assets/style/mixins.less";

.wrapper-overflow-opt {
  padding-top: 12px;

    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
      }
    
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }


      &:hover {
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgb(186, 192, 198);
          }
      }
    max-height: calc(100vh - (70px + 100px));
}

.sider {
  width:18vw !important;
  max-width: 250px !important;
}

.table-add-pack {
  :global(.ant-table-cell) {
    :global(.ant-form-item) {
      margin-bottom: 0;
    }
  }
}