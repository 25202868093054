@import "../../../assets/style/mixins.less";

.modal-wrapper {
  :global(.ant-modal-header) {
    border-bottom: none;
    border-radius: 20px;
    padding-bottom: 0;
  }
  :global(.ant-modal-content) {
    border-radius: 20px;
  }
}

.modal-content-wrapper {
  padding-inline: 12px;
}

.modal-content-container {
  background-color: #f5f7fb;
  width: 100%;
  height: 460px;
  padding: 12px;
  border-radius: 15px;
  color: @navy1;
  overflow: hidden;
  overflow-y: scroll;
  .style-scroll-bar-hide(10px,10px);

  b {
    font-weight: bold !important;
    font-size: 14px;
  }
  strong {
    font-weight: bold !important;
  }
}

.modal-loading-chat {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: 15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
}

.drag-wrapper {
  display: none;
  //  height: 450px;
  background: transparent;
  position: fixed;
  z-index: 1000;
  left: 50%;
  
  .drag-container {
    transform: translateX(-50%);
    transition: ease-in-out 0.2s;
    width: 450px;
    height: 520px;
    box-sizing: border-box;
    cursor:grab;
    &:active {
      cursor:grabbing;
    }
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px #00000025;
  }
}

.generator-html {
  ul {
    margin-bottom: 0;
  }
  padding: 8px;
  font-size: 14px;
  // text-transform: uppercase;
}