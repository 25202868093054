.collapse {
  display: flex;
  flex-direction: column;
  gap: 12px;
  :global(.ant-collapse-header-text) {
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: 16px;
  }

  :global(.ant-collapse-item) {
    background-color: #f4f7ff;
    border-radius: 10px !important;
    border-bottom: none;
  }
}

:global(.ant-collapse) {
  background-color: #fff;
}
