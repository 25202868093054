@import "./fonts.less";
@import "./vars.less";
@import "~antd/dist/antd.less";

@border-radius-base: 5px;
@primary-color: @main-color;
@switch-color: @blue2;
@radio-dot-color: @blue2;
@checkbox-color: @blue2;
@select-item-selected-bg: @main-color;
@select-item-selected-color: #ffffff;
@input-color: @navy1;
@input-bg: #f5f7fb;
@input-placeholder-color: #aaafcf;
@input-border-color: #f5f7fb;
@select-background: #f5f7fb;
@select-border-color: #f5f7fb;
@heading-color: @navy1;
@label-color: @navy1;
// @icon-color: #999999;
@select-selection-item-bg: @blue2;
@table-header-bg: #fff;
// @table-header-color: @navy1;
//@table-row-hover-bg: #6576ff;
//@table-row-hover-color: #FFF;
@table-bg: transparent;
@table-selected-row-bg: #fff;
// @table-selected-row-hover-bg: @navy1;
@layout-body-background: #f5f7fb;
@layout-header-background: #fff;
@layout-sider-background: @navy1;
@layout-header-padding: 0px;
@layout-header-height: @header-height;
@modal-body-padding: 24px 0px;
@drawer-header-padding: 0px;
@drawer-body-padding: 0px;
@menu-dark-bg: @layout-sider-background;
@menu-dark-item-active-bg: @main-color;
@menu-item-active-border-width: 0px;
@menu-dark-inline-submenu-bg: @navy1;
@menu-popup-bg: @navy1;

*:not(.ck *) {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
  font-weight: 600 !important;
}

h2,
h3,
h5,
h6,
p,
div,
a,
span {
  margin-bottom: 0;
  font-size: 13px;
}
h1 {
  font-size: 22px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
label {
  font-weight: 700 !important;
}

.green-txt {
  color: @green1;
}
.blue-txt {
  color: @main-color;
}
.navy-txt {
  color: @navy1;
}
.orange-txt {
  color: @orange1;
}
.red-txt {
  color: @red1;
}
.bold-txt {
  font-weight: bold !important;
}
.cyan_blue-txt {
  color: #16C0FF;
}

.orange-checkbox {
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    &::after {
      border-color: @orange1;
    }
  }

  .ant-checkbox-inner {
    border-color: @orange1;
  }
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}
.virtual-table {
  .ant-table-body {
    cursor: pointer;
  }
}
.virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}
[data-theme="dark"] .virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #303030;
  background: #141414;
}
.ant-notification-bottomLeft {
  z-index: 999999;
}

input,
textarea {
  font-size: 13px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #9bb5f7 ;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) .ant-select-item-option-content {
  color:#fff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ffffff;
  font-weight: 600;
  background-color: #6576ff !important;
}

.myCustomButtonClass {
  color: blue !important;
  background-color: yellow !important;
}

.ant-select-auto-complete {
  color: #2c3782;
}
.ant-select-item-option-content {
  color: #2c3782;
}

.light-txt {
  font-weight: 400 !important;
}

.tox-menu {
  max-height: 350px !important;
}

.ant-select * {
  font-weight: 600;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners, .ck.ck-editor__main {
  min-height: 450px;
  max-height: 450px;
}

.ck.ck-editor__main {
  overflow: auto;
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by {
  display: none;
}

.ck-content ul {
  padding-left: 40px;
  // color: black;
}

.ck-content span {
  font-size: inherit;
  vertical-align: unset;
}

.ck-content {
  color: black;
}

.ck.ck-dropdown__panel {
  max-height: 500px;
  overflow: auto;

  .ck-button__label {
    font-size: 16px !important;
  }
}

textarea {
  color: black;
}

.ck,
.ck * {
  font-family: "Times New Roman", sans-serif;
  font-weight: 400;

  h1, h2, h3, h4, h5, h6, b, strong {
    font-weight: 600;
    font-family: inherit;
  }

  ul {
    margin: 0;
    margin-bottom: 5px;
  }

  span, i, u, s {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
  sup, sub {
    font-weight: inherit;
    font-family: inherit;
  }

  table, .table, table tr {
    height: auto !important;
    max-width: 100% !important;
  }

  table td {
    min-width: 20%;

    > p {
      font-size: 0px;
    }

    .image {
      margin-top: 0;
      margin-bottom: 0;
    }

    &:not(:has(img)) {
      > p {
        font-size: 12px;
      }
    }
  }

  .image, img {
    max-width: 100% !important;
  }
}
body > iframe:not([src="about:blank"]) {
  display: none;
}


.image-inline {
  width: 100% ;
}
