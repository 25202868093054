.input {

    padding: 4px 0;
    text-align: center;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
}

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
}