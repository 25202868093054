@import "../../assets/style/mixins.less";
.modal {
  :global(.ant-modal-body) {
    padding: 16px 20px;
  }
}
.content {
  border-radius: 15px;
  background: #f5f7fb;
  padding: 12px;
  color: @navy1;


  :global(.ant-tree) {
    background: #f5f7fb;
  }

  :global(.ant-tree-checkbox) {
    margin: 0;
  }

  :global(.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal) {
    padding: 0 8px;
  }

  :global(.ant-tree-treenode.ant-tree-treenode-switcher-close) {
    display: flex;
    align-items: center;
  }

  :global(.ant-tree-checkbox-inner) {
    border-radius: 5px;
  }

  :global(.ant-tree-title) {
    font-size: 14px;
    color: @navy1;
  }

  :global(.ant-tree-checkbox-inner) {
    border-radius: 5px;
  }
  :global(.ant-tree-switcher-noop) {
    display: none;
  }

  :global(.ant-tree-indent) {
    display: none;
  }
}

.content-list {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(186, 192, 198);
      border-radius: 10px;
    }
}


.content-title {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.title-content {
  padding: 0 15px;

  p {
    color: @navy1;
    white-space: pre-line; // break words after "\n"
  }
}
.red-txt h1 {
  color: #ff5855 !important;
}

.red-btns button {
  border-color: #ff5855 !important;
  color: #ff5855 !important;

  &.ok-btn {
    color: #fff !important;
    background-color: #ff5855 !important;
    &:disabled {
      opacity: 0.4;
    }
  }
}
