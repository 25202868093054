.pagination{
    width: 100%;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.size-changer__container{
    display: flex;
    align-items: center;
    gap: 8px
}

.size-select{
    width: 60px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}

.pagination-action{
    :global(.ant-pagination-item){
        background-color: transparent;
        border-radius: 9999px;
        border: none;
        color: #2C3782;
        margin-right: 0;
        a{
            line-height: 32px;
        }
    }
    :global(.ant-pagination-item-active){
        a{
            color: #fff;
        }
        background: #6576FF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    }
    :global(.ant-pagination-jump-next-custom-icon){
        color: #2C3782;
        transform: scale(0.6);
    }
    :global(.ant-pagination-prev){
        line-height: 32px;
    }
    :global(.ant-pagination-next){
        line-height: 32px;
        margin-left: 10px;
    }
    :global(.ant-pagination-disabled){
        opacity: 0.5;
    }
}

.text--fade {
    color: #999999;
    font-weight: 500!important;
}