@import "../../assets/style/mixins.less";

.container {
  padding: 0 15px;

  .file-name {
    margin-top: 10px;
    border: 1px solid #ececec;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      color: #333;
    }

    .preview-btn {
      font-size: 12px;
      line-height: 1em;
      color: #333;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.table {
  padding: 0 25px;
}

.template-btn {
  cursor: pointer;
  margin-top: 5px;

  * {
    color: @main-color;
    margin-bottom: 0;
  }

  svg {
    margin-right: 5px;
  }

  &:hover p {
    text-decoration: underline;
  }
}
