.container {
  position: relative;

  .popup {
    min-width: 200px;
    background-color: #fff;
    position: absolute;
    box-shadow: 0 0 10px #00000025;
    right: 18px;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &.visible {
      visibility: visible;
      opacity: 1;
    }

    * {
      line-height: 1.2em;
      white-space: nowrap;
    }
  }

  .content {
    z-index: 0;
  }
}
