@import '../../../assets/style/mixins.less';

@chatFrameHeight : 45vh;
.input-chat {
    background-color:#fff;
    display:flex;
    position:sticky;
    align-items:center;
    padding-inline:16px;
    bottom:0;
    left:0;
    right:0;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;
    height:68px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

}

.float-btn {
    background-color: transparent;
    display: block;
    position:fixed;
    right: 6vw;
    bottom: 5vh;
    cursor: pointer;
    z-index: 1000;
}

.chat-frame-container {
    width:469px;
    min-height:@chatFrameHeight;
    background-color: #F6FBFF;
    border-radius:20px;
}


.chat-frame-container__wrapper--svg {
    background-color:#F1F1F1;
    border-radius:50%;
    width:40px;
    height:40px;
    display:flex;
}

.title-header {
    font-size:16px;font-weight:600;color:#000;
}

.messageContainer {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    max-width: 300px;
    padding:16px 12px !important;
    min-height:41px;
    margin-bottom: 8px;
    border-radius:15px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

    .loadingMessageContainer {
        position: relative;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
      
        &::before, &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
      }
      &::before {
        left: -15px;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }
      &::after {
        left: 15px;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }
      

    }
}

.messageContainer {
        display: inline-flex;
    align-items: center;
    background-color: #fff;
    max-width: 300px;
    padding:16px 12px !important;
    min-height:41px;
    margin-bottom: 8px;
    border-radius:15px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.chat-overlay-container {
    :global(.ant-popover-content) {
        :global(.ant-popover-inner) {
            border-radius:20px;
          :global(.ant-popover-inner-content) {
            border-radius:20px;
            padding:0px;
            border-radius:20px;
          }
        }
      }
}

.chat-header {
    height:53px;background-color:#fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-inline:24px;
    display:flex;align-items:center; gap:10px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

}

.cssChatContainer {
    min-height: @chatFrameHeight;
    padding-block: 8px;
    padding-inline: 6px;
    padding-right: 8px;
    max-height: calc(@chatFrameHeight + 100px);
    .style-scroll-bar-hide(10px,10px);
    overflow: hidden;
    overflow-y: auto;

}

.btn {
  cursor: pointer;
}
.tag-name {
  margin-left: 4px;
}

@keyframes dot-flashing {
    0% {
      background-color: #9880ff;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }

  