@import "../../assets/style/mixins.less";

.search-icon {
  color: @main-color !important;
  font-size: 18px;
  margin-right: 5px;
}

.popup {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px #00000025;
  max-height: 50vh;
  overflow: auto;

  .head {
    display: flex;
    align-items: center;

    h4 {
      padding: 10px 15px;
      margin-bottom: 0 !important;
    }

    .total-num {
      background-color: @main-color;
      color: #fff;
      padding-inline: 5px;
      border-radius: 5px;
      height: 28px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.table {
  :global(.ant-table-cell-row-hover) {
    // color: #fff !important;
    cursor: pointer;
  }

  :global(.ant-table-cell) {
    background-color: #fff;
    color: @navy1;
    padding-block: 10px;
  }

  :global(.ant-table-thead) {
    box-shadow: 0 0 4px #00000010;

    :global(.ant-table-cell) {
      border: none !important;
      background-color: #f9f9f9;

      &::before {
        width: 0px !important;
      }
    }
  }
}
