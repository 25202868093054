.modal-wrapper :global(.ant-modal-header) {
  border-bottom: none;
  border-radius: 20px;
  padding-bottom: 0;
}
.modal-wrapper :global(.ant-modal-content) {
  border-radius: 20px;
}
.modal-content-wrapper {
  padding-inline: 12px;
}
.modal-content-container {
  background-color: #f5f7fb;
  width: 100%;
  height: 460px;
  padding: 12px;
  border-radius: 15px;
  color: #2c3782;
  overflow: hidden;
  overflow-y: scroll;
  /* width */
  /* Handle */
  /* New */
}
.modal-content-container::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
.modal-content-container::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}
.modal-content-container b {
  font-weight: bold !important;
  font-size: 14px;
}
.modal-content-container strong {
  font-weight: bold !important;
}
.modal-loading-chat {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.modal-loading-chat::before,
.modal-loading-chat::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.modal-loading-chat::before {
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.modal-loading-chat::after {
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
.drag-wrapper {
  display: none;
  background: transparent;
  position: fixed;
  z-index: 1000;
  left: 50%;
}
.drag-wrapper .drag-container {
  transform: translateX(-50%);
  transition: ease-in-out 0.2s;
  width: 450px;
  height: 520px;
  box-sizing: border-box;
  cursor: grab;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px #00000025;
}
.drag-wrapper .drag-container:active {
  cursor: grabbing;
}
.generator-html {
  padding: 8px;
  font-size: 14px;
}
.generator-html ul {
  margin-bottom: 0;
}
