@import "../../../../assets/style/mixins.less";
.searchPopupTable {
  display: flex;
  gap: 8px;
  :global {
    .ant-input-affix-wrapper,
    input {
      background-color: #ffffff;
    }
  }
}

.table-packet-service {
  thead {
    tr {
      th {
        &:first-child {
          padding-left: 24px !important;
        }
      }
    }
  }

  :global(.ant-table-row-level-0) {
    :global(.ant-table-cell-with-append) {
      svg {
        margin-right: 10px;
      }
    }
  }
}

.InputNumber{
  width: 100%;
}

.modal {
  .button {
    width: 100%;
    height: 36px;
  }

  .addonAfter {
    display: flex;
  }
  :global {
    .ant-modal-body {
      padding: 24px;
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-input-affix-wrapper {
      background-color: #ffffff !important;
      input {
        background-color: #ffffff !important;
      }
    }
  }
  .title {
    font-size: 22px;
    font-weight: 700 !important;
    color: @navy1;
    text-align: center;
  }

  .content {
    margin-top: 24px;
    padding: 24px 12px;
    background-color: #f6f8fe;
    border-radius: 10px;

    input {
      // background-color: #FFFFFF !important;
      // height: 32px;
    }

    .label,
    .des {
      color: #2c3782;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.5px;
      opacity: 0.5;

      display: flex;
      height: 100%;
      align-items: center;
    }

    .des {
      opacity: 1;
    }

    :global(.ant-form-item-explain.ant-form-item-explain-connected){
      display: none!important;
    }
  }
}

.tong-dv {
  margin-top: 12px;
  p {
    font-size: 14px;
    color: #2c3782;
    font-weight: 600;

    span {
      color: #6576ff;
    }
  }
}
.container {
  height: unset !important;
}
.drawerss {
  :global(.ant-drawer-close) {
    display: none;
  }
  :global(.ant-form-item-required) {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    justify-content: flex-end
  }

  :global {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f7fb !important;
      cursor: not-allowed;
  }
  }

  :global(.ant-drawer-header-title) {
    // padding: 18px 30px;
    .search-input {
      width: 256px;
      background: #ffffff;
      border-radius: 30px;
      :global(> input.ant-input) {
        margin-left: 8px;
        background: #ffffff;
      }
    }
    h2 {
      font-size: 22px;
    }
  }

  .spaceDiv {
    width: 100%;
    justify-content: space-between;
  }

  :global(.ant-drawer-body) {
    padding: 12px 15px 0 15px;
  }

  :global(.ant-collapse) {
    background-color: transparent;
    :global(.ant-collapse-header) {
      align-items: center;
      :global(.ant-collapse-arrow) {
        font-size: 14px;
        margin: 4px 7px 0 5px;
      }
      :global(.ant-collapse-header-text) {
        font-size: 18px;
        font-style: normal;
        font-weight: 700 !important;
      }
    }
  }

  :global(.ant-collapse > .ant-collapse-item) {
    border-radius: 10px;
    background: #f4f7ff;
    border-bottom: none;
    margin-bottom: 15px;

    .wrapper {
      border-radius: 10px;
      background: #fff;
      padding: 12px;
    }
  }
}
.footer-drawer {
  position: sticky;
  bottom: 0;
  background: #ffffff;
}

.switch-active {
  display: flex;
  margin-top: 13px;
  align-items: center;
  gap: 24px;

  :global(.ant-form-item) {
    margin-bottom: 0 !important;
  }
}

.tong-dv {
  min-height: 38px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
}

.input-ma-KH {
  :global(.ant-input-group-addon) {
    background-color: #f5f7fb;
  }
}
