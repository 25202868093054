@import "../../assets/style/mixins.less";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    cursor: pointer;

    input {
        display: none;
    }
}