@import "../../assets/style/mixins.less";

.container {
  padding: 0 15px;
  background-color: #f5f7fb !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .title {
    flex-grow: 1;
    display: flex;
    gap: 10px;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }

    .total-num {
      background-color: @main-color;
      color: #fff;
      padding-inline: 5px;
      border-radius: 5px;
      height: 28px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .search {
    background-color: #fff;
    border: 1px solid #eef4ff;
    border-radius: 50px;
    width: 250px;

    input {
      background-color: #fff;
      border-radius: 0px !important;
    }

    .icon {
      color: @main-color;
      margin-right: 5px;
      font-size: 20px;
    }

    &.search-responsive {
      @media screen and (max-width: 1400px) {
        display: none;
      }
    }
  }

  .add-btn {
    .icon {
      color: #fff;
    }
  }

  .actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.searchbar-responsive {
  display: none;

  @media screen and (max-width: 1400px) {
    display: block;
  }
}

.blue-icon {
  color: @main-color;
}

.blue {
  background-color: @main-color;
}
