.style-tabs {
  :global(.ant-tabs-nav)::before {
    display: none
  }
  :global(.ant-tabs-nav-list) {
    margin-left: 12px;
  }
  :global(.ant-tabs-tab) {
    text-transform: uppercase;
    color: #2C3782;
    &:hover {
      color: rgba(101, 118, 255, 1);
    }
  }
  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    font-weight: 700 !important;
  }

  .labelHeader {
    display: flex;
    align-items: center;
  }

  .total{
    min-width: 26px;
    height: 26px;
    background-color:rgba(101, 118, 255, 1);
    color: aliceblue;
    margin-left: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    padding: 0 5px;
  }
}