@import "../../assets/style/mixins.less";
.removeMargin{
    :global(.ant-form-item){
        margin-bottom: 10px !important;
    }
}
.topContent{
    display: flex;
    flex-direction: row;
    flex:1;
   
}
.main-txt {
      color: @navy1;
 }
.sub-txt {
      color: #aaafcf;
      font-size: 12px;
}
.topContent>div{
    margin-right: 40px;
}
 label {
      color: @navy1;
}
.detailBtn{
    padding-inline: 23px;
}
.rowTopbar> p {
    color: #2C3782 !important;
    font-weight: 700 !important;
    font-size: 22px !important;
    padding-bottom: 20px;
    padding-left: 20px;
}
.footer{
    justify-content: end;
    padding: 20px;
    background-color: white;
    // position: fixed;
    bottom: 0;
    // width: 76%;
}
.containerKQCLS{
    background: #f5f7fb;
    height: 100vh;

}
.total-price-ctn {
 margin-top: 20px;
  padding: 0px 15px;
  text-align: end;
  color: @navy1;
  font-weight: 600 !important;
  font-size: 14px !important;

  .total-price {
    color: @green1;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
}
.container {
    background-color: #FFF;
    height: 100vh;
    // overflow: auto;
    :global(.ant-form-item) {
      margin-bottom: 8px;
    }

    .back-icon {
        display: flex;
        padding: 10px;
        border: 2px solid #EDEDED;
        border-radius: 30px;
        margin-right: 20px;
        cursor: pointer;
        svg {
            color: #2C3782;
        }
    }
    .row-button {
        background-color: #F5F7FB;
        padding: 5px;
        border-radius: 10px;
        margin-top: 20px;
        width: fit-content;
        .button{
            border: none;
        }
    }
}
.box-time{
    border: 1px solid #EDEDED;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding:8px 20px;
    h4 {
        margin: 0px !important;
}
    }

.box-container {
    // border: 1px solid #EEEDED;
    // border-radius: 10px;
    padding: 20px;
    padding-right: 0;
    margin-top: 10px;
    //margin: 20px;
    .value {
        height: 40px;
        background-color: #F5F7FB;
        display: flex;
        flex: 1;
        margin-left: 10px;
        //width: fit-content;
        max-width: 50px;
        //padding-left: 0px;
        // justify-content: center;
        // align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        // border-radius: 5px;
    }
    .title {
        font-size: 14px;
        color: #2C3782;
    }
    .content {
        color: #AAAFCF;
        font-size: 12px;
    }
    .box-input{
        border: 1px solid #EEEDED;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
        min-height: 40px;
        .item{
            border: 1px solid #EEEDED;
            border-radius: 5px;
            padding: 5px 15px;
            background-color: #F5F7FB;
            margin-left: 15px;
            p {
                font-size: 14px;
                color: #2C3782;
                margin: 0 10px;
            }
        }
    }
}
.wrap-table{
    background-color: #F5F7FB;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.table-container{
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    :global(.ant-empty){
        padding-top: 10%;
        min-height: calc(100vh - 300px) !important;
    }
}