.popup {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px #00000025;

  .popup-wrapper {
    min-width: 250px;
    max-width: 400px;
  }

  .popup-checkbox {
    padding: 10px 15px;
  }

  .button-row {
    border-top: 1px solid #e0e0e0;
    padding: 10px 15px;
  }

  .label {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 15px;
    color: #2c3782;
    font-weight: 700 !important;
  }

  .item {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    :global(.ant-checkbox-wrapper) {
      color: #2c3782;
    }
  }
}
