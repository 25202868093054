.container {
    display: flex;
    gap: 8px;
    align-items: center;
    .niceBtn {
        display: flex;
        height: 36px;
        min-width: 170px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: none;
        box-shadow: none;
        text-transform: uppercase;
        span {
            font-weight: 700 !important;
        }
        svg {
            path {
                fill: #fff;
            }
        }
        &:hover {
            opacity: 0.7;
        }
    }
    :global(.ant-btn-primary[disabled]) {
        color: #fff;
        opacity: 0.4;
    }
}