@import "../../assets/style/mixins.less";

.parent-uncheck {
  :global(.ant-select-tree-indent) {
    display: none;
  }
}

.input {
  :global(.ant-select-selection-item) * {
    color: #fff;
  }
}

.dropdown {
  :global(.ant-select-tree-switcher.ant-select-tree-switcher-noop) {
    display: none;
  }
  :global(.ant-select-tree-title) {
    color: @navy1 !important;
  }
}
