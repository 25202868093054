@import "../../assets/style/mixins.less";

.container {
  :global(.ant-select-selection-item) {
    color: @navy1;
  }

  :global(.ant-select-selection-item) * {
    color: #fff;
  }

  :global(.ant-select-selector) {
    color: @navy1;
  }
}

.table-head {
  padding: 12px;
  background-color: #fafafa;
  border-bottom: 1px solid #00000006;
  color: @navy1;
  div {
    font-weight: 700 !important;
  }
  :global(.ant-col) {
    white-space: break-spaces;
  }
  // .first-child {
  //   padding-left: 15px;
  //   margin-right: -15px;
  // }
}

.table-body {
  padding: 5px 0;
  :global(.ant-col) {
    white-space: break-spaces;
  }
}

.add-item {
  border: 1px solid #fafafa;
  padding-top: 5px;
}

.dropdown {
  padding: 2px 0 0 0 !important;

  :global(.ant-select-item-option-content) {
    color: @navy1;
  }

  :global(.ant-select-item-option-selected) * {
    color: #fff;
  }

  :global(.ant-select-tree-switcher) {
    width: 10px;
  }

  :global(.ant-select-tree-title) {
    color: @navy1;
  }

  :global(.ant-select-item-option-disabled) {
    background-color: #00000010;

    * {
      color: #00000050;
    }
  }
}

.colCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}