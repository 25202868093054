@import "../../assets/style/mixins.less";
.tableContainer {

    // input[type="date"]::-webkit-inner-spin-button,
    // input[type="date"]::-webkit-calendar-picker-indicator {
    //     display: none;
    //     -webkit-appearance: none;
    // }
    .input{
       border: none;

    }
    margin-inline: 15px !important;
    .button {
        //position: absolute;
        //bottom: 30px;
        //right: 30px
      margin-top: 10px;
    }
    :global(.ant-form-item-label){
        padding: 0px !important;
      }
    :global(.ant-input-number){
        margin-top: 0px !important;
    }
}

.formInput{
    :global(.ant-form-item) {
        margin-bottom: 0px !important;
      }

      :global(.ant-form-item-explain.ant-form-item-explain-connected) {
        display: none !important;
      }

}
