.wrapper-overflow-opt {
  padding-top: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* Handle */
  max-height: calc(100vh - (70px + 100px));
}
.wrapper-overflow-opt::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
.wrapper-overflow-opt::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.wrapper-overflow-opt:hover {
  /* Handle */
}
.wrapper-overflow-opt:hover::-webkit-scrollbar-thumb {
  background: #bac0c6;
}
.sider {
  width: 18vw !important;
  max-width: 250px !important;
}
.table-add-pack :global(.ant-table-cell) :global(.ant-form-item) {
  margin-bottom: 0;
}
.searchPopupTable {
  display: flex;
  gap: 8px;
}
.searchPopupTable :global .ant-input-affix-wrapper,
.searchPopupTable :global input {
  background-color: #ffffff;
}
.table-packet-service thead tr th:first-child {
  padding-left: 24px !important;
}
.table-packet-service :global(.ant-table-row-level-0) :global(.ant-table-cell-with-append) svg {
  margin-right: 10px;
}
.InputNumber {
  width: 100%;
}
.modal .button {
  width: 100%;
  height: 36px;
}
.modal .addonAfter {
  display: flex;
}
.modal :global .ant-modal-body {
  padding: 24px;
}
.modal :global .ant-form-item {
  margin: 0;
}
.modal :global .ant-input-affix-wrapper {
  background-color: #ffffff !important;
}
.modal :global .ant-input-affix-wrapper input {
  background-color: #ffffff !important;
}
.modal .title {
  font-size: 22px;
  font-weight: 700 !important;
  color: #2c3782;
  text-align: center;
}
.modal .content {
  margin-top: 24px;
  padding: 24px 12px;
  background-color: #f6f8fe;
  border-radius: 10px;
}
.modal .content .label,
.modal .content .des {
  color: #2c3782;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  opacity: 0.5;
  display: flex;
  height: 100%;
  align-items: center;
}
.modal .content .des {
  opacity: 1;
}
.modal .content :global(.ant-form-item-explain.ant-form-item-explain-connected) {
  display: none!important;
}
.tong-dv {
  margin-top: 12px;
}
.tong-dv p {
  font-size: 14px;
  color: #2c3782;
  font-weight: 600;
}
.tong-dv p span {
  color: #6576ff;
}
.container {
  height: unset !important;
}
.drawerss :global(.ant-drawer-close) {
  display: none;
}
.drawerss :global(.ant-form-item-required) {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
  justify-content: flex-end;
}
.drawerss :global .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f7fb !important;
  cursor: not-allowed;
}
.drawerss :global(.ant-drawer-header-title) .search-input {
  width: 256px;
  background: #ffffff;
  border-radius: 30px;
}
.drawerss :global(.ant-drawer-header-title) .search-input :global(> input.ant-input) {
  margin-left: 8px;
  background: #ffffff;
}
.drawerss :global(.ant-drawer-header-title) h2 {
  font-size: 22px;
}
.drawerss .spaceDiv {
  width: 100%;
  justify-content: space-between;
}
.drawerss :global(.ant-drawer-body) {
  padding: 12px 15px 0 15px;
}
.drawerss :global(.ant-collapse) {
  background-color: transparent;
}
.drawerss :global(.ant-collapse) :global(.ant-collapse-header) {
  align-items: center;
}
.drawerss :global(.ant-collapse) :global(.ant-collapse-header) :global(.ant-collapse-arrow) {
  font-size: 14px;
  margin: 4px 7px 0 5px;
}
.drawerss :global(.ant-collapse) :global(.ant-collapse-header) :global(.ant-collapse-header-text) {
  font-size: 18px;
  font-style: normal;
  font-weight: 700 !important;
}
.drawerss :global(.ant-collapse > .ant-collapse-item) {
  border-radius: 10px;
  background: #f4f7ff;
  border-bottom: none;
  margin-bottom: 15px;
}
.drawerss :global(.ant-collapse > .ant-collapse-item) .wrapper {
  border-radius: 10px;
  background: #fff;
  padding: 12px;
}
.footer-drawer {
  position: sticky;
  bottom: 0;
  background: #ffffff;
}
.switch-active {
  display: flex;
  margin-top: 13px;
  align-items: center;
  gap: 24px;
}
.switch-active :global(.ant-form-item) {
  margin-bottom: 0 !important;
}
.tong-dv {
  min-height: 38px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
}
.input-ma-KH :global(.ant-input-group-addon) {
  background-color: #f5f7fb;
}
