.popup-lang {
  .item {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &.active {
      background-color: #00000005;
      border-radius: 5px;
    }
  }
}
