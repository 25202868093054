@import "../../assets/style/mixins.less";

.styleTable {
  height: 100%;
  //&.show-gap tbody {
  //  &::before {
  //    content: "@";
  //    display: block;
  //    line-height: 15px;
  //    text-indent: -99999px;
  //  }
  //}
  thead tr th {
    &:global(.ant-table-cell) {
      font-weight: 700 !important;
    }
  }

  thead {
    :global(.ant-table-cell) {
      &::before {
        background-color: transparent !important;
      }
    }
  }
  :global(.ant-table) {
    border-radius: 10px;
    // height: calc(100vh - 180px);
    background: transparent;
  }

  table {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  :global(.ant-table-header) {
    border-radius: 10px;
    background: #F5F7FB;
    &:after {
      content: "@";
      width: 100%;
      //background: #F5F7FB;
      display: block;
      text-indent: -99999px;
      line-height: 12px;
    }
  }

  :global(.ant-table-body) {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  :global(.ant-table-title),
  :global(.ant-table-footer) {
    padding-inline: 20px !important;
  }
  :global(.ant-table-footer) {
    background: #fff;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      color: @navy1;
      background-color: white;
    }
  }
  :global(.ant-table-tbody > tr > td) {
    // border-bottom: 1px solid #f1f1f1 !important;
    // border-right: 1px solid #f1f1f1 !important;
    color: @navy1;
    min-height: 45px;
    height: 45px;
  }
  :global(.ant-table-tbody > tr.ant-table-row-selected > td) {
    background-color: transparent !important;
    color: @navy1;
  }
  :global(.ant-table-body),
  :global(.ant-table-content) {
    :global(.ant-table-cell) {
      cursor: pointer;
      background: #FFFFFF;
    }
    .style-scroll-bar-hide();
  }
  tr {
    th:first-of-type {
      // paddingLeft: `@{sizes.gap}px !important`,
    }
  }
  :global(.ant-pagination-options) {
    position: absolute;
    left: 0;
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    &:before {
      color: #999;
      margin-right: 8px;
      font-size: 12px;
    }
    &:after {
      margin-left: 8px;
      color: #999;
      font-size: 12px;
    }
    :global(.ant-select-selector) {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    }
  }
  :global(.ant-pagination) {
    margin: 0 !important;
    margin-block: 0;
    padding: 15px 0;
    background-color: transparent;
    border-radius: 0 0 5px 5px;
    //border-top: 1px solid #eaeaea;
    * {
      font-size: 12px;
    }
    :global(.ant-pagination-item) {
      a {
        color: @navy1;
      }
      border-radius: 100%;
    }
    :global(.ant-pagination-item-active) {
      background-color: @main-color;
      transition: all 0s;
      border-radius: 100%;
      a {
        color: #fff;
      }
    }
  }
  :global(.ant-spin-nested-loading){
    height: 100%;
  }
  :global(.ant-spin-nested-loading>.ant-spin-container){
    height: 100%;
    padding-bottom: 54px;
  }
  :global(.ant-pagination){
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.navigateBtn {
  display: flex;
  gap: 5px;
  align-items: center;
  :global(.label) {
    font-size: 12px;
    color: #999999;
  }
  svg {
    font-size: 10px;
  }
}

.stylePaginationCenter {
  :global(.ant-pagination) {
    justify-content: center;
  }
}

.langVN {
  :global(.ant-pagination-options) {
    &:before {
      content: "Hiển thị";
    }
    &:after {
      content: "trên 1 trang";
    }
  }
}
.langEN {
  :global(.ant-pagination-options) {
    &:before {
      content: "Show";
    }
    &:after {
      content: "on a page";
    }
  }
}