@import "../../assets/style/mixins.less";

.header {
  padding: 9px 25px;
  // background-color: #f3f6f9;
  background-color: #F5F6FA;

  svg {
    // color: #fff;
  }

  .icon {
    font-size: 30px;
    margin-top: 3px;

    svg {
      color: @main-color;
      font-size: 22px;
    }
  }

  h1 {
    font-size: 22px !important;
  }
}

.container {
  // height: 100%;
  h4 {
    font-weight: 600 !important;
  }
}

.button {
  min-width: 120px;
}

.button-row {
  :global(.ant-btn-primary) {
    display: flex;
    align-items: center;
  }
}

.pending_screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
}

// :global(.ant-drawer-mask),
// :global(.ant-drawer-content-wrapper) {
//   z-index: 1052;
// }
